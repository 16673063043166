import axios from "axios";
import { constant } from "constant";

const isDevelopment = process.env.NODE_ENV === 'development';
const BASE_URL = isDevelopment 
  ? window.location.hostname === 'localhost' 
    ? 'http://localhost:5001'
    : `http://${window.location.hostname}:5001`
  : 'https://vinata.vercel.app';

console.log('Current environment:', process.env.NODE_ENV);
console.log('Using base URL:', BASE_URL);

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add debug logging for requests
axiosInstance.interceptors.request.use(request => {
  console.log('Making request to:', request.url);
  console.log('Request headers:', request.headers);
  return request;
});

// Add debug logging for responses
axiosInstance.interceptors.response.use(
  response => {
    console.log('Received response:', response.status);
    return response;
  },
  error => {
    console.error('Request failed:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

// Token refresh logic
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // Clear tokens and redirect to login if refresh fails
      const clearAuthAndRedirect = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/auth/sign-in';
      };

      try {
        clearAuthAndRedirect();
        return Promise.reject(error);
      } catch (refreshError) {
        clearAuthAndRedirect();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);
// Post API
// Post API
export const postApi = async (path, data, login) => {
  try {
    const fullUrl = `/${path}`;
    console.log("Full API URL:", fullUrl);
    console.log("Request Data:", data);

    // Create config object with headers
    const config = {
      headers: {
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
      }
    };

    // If it's FormData, remove Content-Type from axios instance headers
    if (data instanceof FormData) {
      delete axiosInstance.defaults.headers['Content-Type'];
    }

    let result = await axiosInstance.post(fullUrl, data, config);

    // Restore default Content-Type if needed
    if (data instanceof FormData) {
      axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    }

    console.log("API Response:", result);

    if (result.data?.token && result.data?.token !== null) {
      if (login) {
        localStorage.setItem('token', result.data?.token);
      } else {
        sessionStorage.setItem('token', result.data?.token);
      }
      localStorage.setItem('user', JSON.stringify(result.data?.user));
    }
    return result;
  } catch (e) {
    console.error("API Error:", e);
    if (e.response) {
      console.error("Error Response:", e.response.data);
      console.error("Error Status:", e.response.status);
    } else if (e.request) {
      console.error("No Response Received:", e.request);
    } else {
      console.error("Error Message:", e.message);
    }
    throw e;
  }
};

// Put API
export const putApi = async (path, data) => {
  try {
    const fullUrl = `/${path}`;
    let result = await axiosInstance.put(fullUrl, data);
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// Delete API
export const deleteApi = async (path, data) => {
  try {
    const fullUrl = `/${path}`;
    let result = await axiosInstance.delete(fullUrl, {
      data, // Sending data in the request body
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// Delete Many API
export const deleteManyApi = async (path, data) => {
  try {
    const fullUrl = `/${path}`;
    let result = await axiosInstance.post(fullUrl, data);
    if (result.data?.token && result.data?.token !== null) {
      localStorage.setItem('token', result.data?.token);
    }
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// Get API
export const getApi = async (path, id) => {
  try {
    const fullUrl = `/${path}${id || ''}`;
    let result = await axiosInstance.get(fullUrl);
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// Log User Access
export const logUserAccess = async (logData, username) => {
  try {
    const fullUrl = `/api/log/user-access`;
    const data = { ...logData, username }; // Include username in the data
    await axiosInstance.post(fullUrl, data);
    console.log("User Access Logged");
  } catch (e) {
    console.error("Error logging user access:", e);
  }
};

// Search API
export const searchApi = async (query) => {
  console.log("API Search Query:", query);
  try {
    const fullUrl = `/api/search?query=${encodeURIComponent(query)}`;
    let result = await axiosInstance.get(fullUrl);
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
